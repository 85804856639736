import { USERTYPES } from './utils';

export function getToken() {
    return localStorage.getItem('accessKey');
}

export function getUserID() {
    return parseInt(localStorage.getItem('id'), 10);
}

export function getSecureID() {
    return localStorage.getItem('secureid');
}

export function getRoleID() {
    return parseInt(localStorage.getItem('role'), 10);
}

export function getSTUserStatus() {
    return localStorage.getItem('stuserstatus');
}

export function getUserType() {

    let role = localStorage.getItem('role');
    var usertype = "";
    switch (role) {
        case '15':
            usertype = USERTYPES.Admin;
            break;
        case '10':
        case '4':
        case '5':
            usertype = USERTYPES.Broker;
            break;
        // case '3':
        //     usertype = USERTYPES.Broker
        // case '4':
        //     usertype = USERTYPES.Vendor
        //     break;
        default: break;
    }
    return usertype;
}

export function getName() {
    return localStorage.getItem('name');
}

export function getEmail() {
    return localStorage.getItem('email');
}

export function getBrokerType() {
    return parseInt(localStorage.getItem('brokertype'), 10);
}

export function removeLocalStorage() {
    localStorage.removeItem('accessKey');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('role');
    localStorage.removeItem('id');
    localStorage.removeItem('secureid');
    localStorage.removeItem('brokertype');
}

