import React, { Component } from 'react';
import HubspotForm from 'react-hubspot-form'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { CONFIG } from '../../../Utils/config';
import { REGIONS } from '../../../Utils/utils';

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isContactPopup: this.props.open
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    toggleContactClose() {
        this.setState({ isContactPopup: false });
        this.props.parentMethod();
    }
    
    render() {
        const { isContactPopup } = this.state;
        return (
            <div className="contactus-scroll">
                <Modal size="md" isOpen={isContactPopup} toggle={this.toggleContactClose.bind(this)} className="pop-up-model-ContactUs">
                    <ModalHeader toggle={this.toggleContactClose.bind(this)}>
                        Contact Us
                        </ModalHeader>
                    <ModalBody>
                    {CONFIG.REGION_ID === REGIONS.US ?
                    <HubspotForm
                        portalId='4297816'
                        formId='b6054010-9aa6-40e6-b08d-1778a64fabf6'
                    />
                    : CONFIG.REGION_ID === REGIONS.UK ?
                    <HubspotForm
                        portalId='4297816'
                        formId='5228a9ac-77d7-416d-8056-d5d7afa7d565'
                    />
                    : ''}                        
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default connect()(ContactUs);
