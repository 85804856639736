import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Router, Switch } from 'react-router-dom';
import Home from '../Views/Home/Home';
import './FullPageLoader.css';
import { HomeLayoutRoute, UserLayoutRoute, AdminLayoutRoute, ResultLayoutRoute } from './RouteLayout';
import History from '../History';

const UserRegistration = lazy(() => import('../Views/Account/UserRegistraton/UserRegistraton'));
const UserManagement = lazy(() => import('../Views/Admin/UserManagement/UserManagement'));
const Authendication = lazy(() => import('../Views/Account/Authendication/Authendication'));
const ForgotPassword = lazy(() => import('../Views/Account/ForgotPassword/ForgotPassword'));
const ResetPassword = lazy(() => import('../Views/Account/ResetPassword/ResetPassword'));
const Categories = lazy(() => import('../Views/Admin/Categories/Categories'));
const AddListing = lazy(() => import('../Views/Admin/AddListing/AddListing'));
const PropertValuation = lazy(() => import('../Views/PropertyValuation/PropertValuation'));
const AboutUs = lazy(() => import('../Views/AboutUs/AboutUs'));
const VendorSearchList = lazy(() => import('../Views/Admin/Vendor/VendorSearchList/VendorSearchList'));
const VendorProfile = lazy(() => import('../Views/Shared/VendorProfile/VendorProfile'));
const AdminProfile = lazy(() => import('../Views/Admin/AdminProfile/AdminProfile'));
const SearchVendor = lazy(() => import('../Views/Vendor/SearchVendor/SearchVendor'));
const VendorList = lazy(() => import('../Views/Vendor/VendorList/VendorList'));
const SearchProperty = lazy(() => import('../Views/Admin/PropertyListings/SearchProperty/SearchProperty'));
const WatchList = lazy(() => import('../Views/WatchList/WatchList'));
const PageNotFound = lazy(() => import('../Views/PageNotFound/PageNotFound'));
const PropertyGeoCode = lazy(() => import('../Views/Migration/PropertyGeoCode/PropertyGeoCode'));
const MyProfile = lazy(() => import('../Views/Account/MyProfile/MyProfile'));
const AddProperty = lazy(() => import('../Views/Shared/AddProperty/AddProperty'));
const VendorProfileList = lazy(() => import('../Views/User/VendorProfileList/VendorProfileList'));
const PropertyList = lazy(() => import('../Views/User/PropertyList/PropertyList'));
const SearchResult = lazy(() => import('../Views/SearchResult/SearchResult'));
const Profile = lazy(() => import('../Views/Vendor/Profile/Profile'));
const PropertyView = lazy(() => import('../Views/Shared/PropertyView/PropertyView'));
const DashBoard = lazy(() => import('../Views/User/DashBoard/DashBoard'));

class RouteComp extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={<div className="loader-wrap"><div className="page-loading"></div></div>}>
          <Router basename='/index.html' history={History}>
            <Switch>
              <HomeLayoutRoute path="/resetpassword/:PasswordResetToken" component={ResetPassword} />
              {/* <HomeLayoutRoute path="/resetpassword/" component={ResetPassword} /> */}
              <HomeLayoutRoute path="/forgotpassword" component={ForgotPassword} />
              <HomeLayoutRoute exact path="/" component={Home} />
              <HomeLayoutRoute exact path="/signin" component={Home} />
              <HomeLayoutRoute exact path="/contact" component={Home} />
              <HomeLayoutRoute exact path="/signup" component={UserRegistration} />
              <HomeLayoutRoute exact path="/valuation" component={PropertValuation} />
              <HomeLayoutRoute exact path="/aboutus" component={AboutUs} />
              {/* <HomeLayoutRoute exact path="/loanCalculator" component={LoanCalculator} /> */}
              <HomeLayoutRoute path="/logins/:authurization/" component={Authendication} />
              <HomeLayoutRoute path="/login/:authurization/" component={Authendication} />

              {/* <HomeLayoutRoute exact path="/search/:searchid/" component={SearchResult} /> */}
              {/* <HomeLayoutRoute exact path="/newsAndEvents/:keyword/:typeId/" component={NewsSearchList} /> */}
              {/* <HomeLayoutRoute exact path="/news/:titlename/:newsId/" component={NewsDetails} /> */}
              {/* <HomeLayoutRoute exact path="/newsAndEvents" component={NewsEvents} /> */}
              <HomeLayoutRoute exact path="/process/geocode" component={PropertyGeoCode} />

              <ResultLayoutRoute exact path="/listings/" component={SearchResult} />
              <ResultLayoutRoute exact path="/listings/:searchId/" component={SearchResult} />
              <HomeLayoutRoute exact path="/vendors" component={SearchVendor} />
              <HomeLayoutRoute exact path="/vendors/list/:keyword" component={VendorList} />
              <HomeLayoutRoute exact path="/vendors/list/:parentCategoryName/:childCategoryID/" component={VendorList} />
              <HomeLayoutRoute exact path="/vendors/list/:parentCategoryName/:childCategoryName/:childCategoryID/" component={VendorList} />
              <HomeLayoutRoute exact path="/vendors/profile/:permalink" component={Profile} />
              <HomeLayoutRoute exact path="/property/:propertyAddress" component={PropertyView} />
              <HomeLayoutRoute exact path="/property/:stateId/:city/:address/:propertyName/:sourceId" component={PropertyView} />
              <HomeLayoutRoute exact path="/pagenotfound" component={PageNotFound} />

              <AdminLayoutRoute path="/admin/usermanagement" component={UserManagement} />
              <AdminLayoutRoute path="/admin/editlisting/:newsId/" component={AddListing} />
              <AdminLayoutRoute path="/admin/addlisting" component={AddListing} />
              {/* <AdminLayoutRoute path="/admin/newsandevents" component={NewsAndEvents} /> */}
              <AdminLayoutRoute path="/admin/vendors" component={VendorSearchList} />
              <AdminLayoutRoute path="/admin/vendor-profile/:vendorId" component={VendorProfile} />
              <AdminLayoutRoute path="/admin/vendor-profile" component={VendorProfile} />
              <AdminLayoutRoute path="/admin/categories" component={Categories} />
              <AdminLayoutRoute path="/admin/properties" component={SearchProperty} />
              <AdminLayoutRoute path="/admin/editproperty/:propertyId/:statusId" component={AddProperty} />
              <AdminLayoutRoute path="/admin/editproperty/:propertyId/" component={AddProperty} />
              <AdminLayoutRoute path="/admin/addproperty" component={AddProperty} />
              <AdminLayoutRoute path="/admin/profile" component={AdminProfile} />

              <UserLayoutRoute exact path="/user/dashboard" component={DashBoard} />
              <UserLayoutRoute exact path="/user/vendors" component={VendorProfileList} />
              <UserLayoutRoute exact path="/user/profile" component={MyProfile} />
              <UserLayoutRoute path="/user/vendor-profile/:vendorId" component={VendorProfile} />
              <UserLayoutRoute path="/user/vendor-profile" component={VendorProfile} />
              <UserLayoutRoute exact path="/user/profile/:permalink" component={Profile} />
              <UserLayoutRoute exact path="/user/watchlist" component={WatchList} />
              <UserLayoutRoute exact path="/user/properties" component={PropertyList} />
              <UserLayoutRoute exact path="/user/addproperty" component={AddProperty} />
              <UserLayoutRoute path="/user/editproperty/:propertyId/:statusId" component={AddProperty} />
              <UserLayoutRoute path="/user/editproperty/:propertyId" component={AddProperty} />
            </Switch>
          </Router>
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default RouteComp;