import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import logo_admin from './../../Assets/Img/Lrv-logo.png';
import userimg from './../../Assets/Img/user.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ViewListIcon from '@material-ui/icons/ViewList';
import StoreIcon from '@material-ui/icons/Store';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import './AdminLayout.css';
import { FullPageLoader } from '../../Core/FullPageLoader';
import CustomizedSnackbars from '../../redux/snackbar';
import { Container, Row } from 'reactstrap';
import { removeLocalStorage, getName } from '../../Utils/localStorage';
import axios from 'axios';
import history from '../../History';
import { showLoader, hideLoader } from '../../redux/ducks/application';
import store from '../../redux/configureStore';

const drawerWidth = 200;
const { dispatch } = store;

axios.interceptors.request.use(
    (conf) => {
        if (!conf.headers['Authorization']) {

        }
        dispatch(showLoader());
        return conf;
    },
    (error) => {
        dispatch(hideLoader());
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(response => {
    dispatch(hideLoader());
    return response;
}, error => {
    dispatch(hideLoader());
    if (!error.response) {
        //alert('NETWORK ERROR')
    }
    else if (error.response.status === 401) {
        removeLocalStorage();
        history.push('/')
    }
    // else if (error.response.status === 500) {
    //     removeLocalStorage();
    //     history.push('/')
    // }
    return Promise.reject(error);
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}));



export default class AdminLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            mobileOpen: false
        }
    }

    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }

    signOut() {
        removeLocalStorage();
        this.props.children.props.history.push({
            pathname: `/`
        });
    }

    componentDidMount() {
        var name = getName();
        this.setState({ name });
    }

    render() {
        const { name } = this.state;
        return (
            <div className={useStyles.root} id="left-nav-bar">
                <Drawer
                    className={useStyles.drawer}
                    variant="permanent"
                    usestyles={{
                        paper: useStyles.drawerPaper,
                    }}
                    anchor="left"
                >
                    <div className={useStyles.toolbar} />
                    <div className="admin-logo">
                        <Link to="/">
                            <img src={logo_admin} className="logo-adm" alt="RVParkIQ" />
                        </Link>
                    </div>
                    <Divider />
                    <List className="admin-menu-text">
                        <Link to="/admin/profile" className={window.location.pathname === '/admin/profile' ? "admin-menu active-d" : "admin-menu"}>
                            {['My Account'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><PersonIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </Link>
                        <Divider />
                        <Link to="/admin/usermanagement" className={window.location.pathname === '/admin/usermanagement' ? "admin-menu active-d" : "admin-menu"}>
                            {['User Management'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><PeopleIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </Link>
                        {/* <Divider />
                        <Link to="/admin/newsandevents" className={window.location.pathname === '/admin/newsandevents' || window.location.pathname == '/admin/addlisting' || window.location.pathname.indexOf("editlisting") > -1 ? "admin-menu active-d" : "admin-menu"}>
                            {['News & Events'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><InsertChartIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </Link> */}
                        <Divider />
                        <Link to="/admin/properties" className={window.location.pathname === '/admin/properties' || window.location.pathname === '/admin/addproperty' || window.location.pathname.indexOf('editproperty') > -1 ? "admin-menu active-d" : "admin-menu"}>
                            {['Property Listings'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><AddBoxIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </Link>
                        <Divider />

                        <Link to="/admin/vendors" className={window.location.pathname === '/admin/vendors' || window.location.pathname === '/admin/vendor-profile' || window.location.pathname.indexOf('vendor-profile') > -1 ? "admin-menu active-d" : "admin-menu"}>
                            {['Manage Vendor Profile'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><StoreIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </Link>
                        <Divider />
                        <Link to="/admin/categories" className={window.location.pathname === '/admin/categories' ? "admin-menu active-d" : "admin-menu"}>
                            {['Category Management'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><ViewListIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </Link>
                        <Divider />
                        <div onClick={this.signOut.bind(this)} className="admin-menu">
                            {['Logout'].map((text, index) => (
                                <ListItem button key={text}>
                                    <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </div>
                    </List>
                    <Divider />
                </Drawer>
                <main className={useStyles.content}>
                    <div className={useStyles.toolbar} />
                    <Container fluid className="admin-top">
                        <Row className="adminrow">
                            <div className="col-sm-8">
                                <div className="gobackhome">
                                    <Link to="/">
                                        <HomeIcon />
                                        <span>Go to Home page</span>
                                    </Link>
                                </div>
                                <CustomizedSnackbars />
                                <FullPageLoader />
                            </div>
                            <div className="col-sm-4">
                                <Row>
                                    <img src={userimg} className="usericon" alt="User Image" />
                                    <ul>
                                        <li>
                                            <div className="welcome-header">
                                                <div className="top-welcome">Welcome</div>
                                                <span className="text-uppercase">{name}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </Row>
                            </div>
                        </Row>
                    </Container>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

