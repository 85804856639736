import React, { Component } from 'react';
import HubspotForm from 'react-hubspot-form'
import { Link } from "react-router-dom";
import './Footer.css';
import axios from 'axios';
import { CONFIG } from './../../Utils/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { USERTYPES, REGIONS, getDateFormat } from '../../Utils/utils';
import { faLinkedin, faTwitter, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope, faPhone, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col } from 'reactstrap';
import { getToken, getUserType } from '../../Utils/localStorage';
import { connect } from 'react-redux';
import { setSnackbar } from '../../redux/ducks/snackbarReducer';
import vendor_no from './../../Assets/Img/ventor-list.png';
import moment from 'moment';
import ContactUs from '../../Views/Shared/ContactUs/ContactUs';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            userType: '',
            isContactPopup: false,

        };

        this.openContactForm = this.openContactForm.bind(this);
        this.closeContactForm = this.closeContactForm.bind(this);
    }

    componentDidMount() {
        var token = getToken();
        var userType = getUserType();
        this.getNewProperties();
        this.setState({ token, userType });
    }

    handleClickOutside() {
        if (document.getElementById("dvclose")) {
            document.getElementById("dvclose").click();
        }
    }

    openContactForm() {
        this.setState({ isContactOpen: true });
    }

    closeContactForm() {
        this.setState({ isContactOpen: false });
    }

    getNewProperties() {

        axios.get(CONFIG.API_URL + 'home/new/listings/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                this.setState({ footerProperties: response.data });
            }
        })
            .catch(err => {
                if (err.response !== null && err.response !== undefined) {
                    this.props.dispatch(setSnackbar(true, "error", err.response.data));
                }
                else {
                    this.props.dispatch(setSnackbar(true, "error", "Something wrong happend"));
                }
            });
    }

    submitProperty() {
        const { token, userType } = this.state;
        if (token) {
            if (userType === USERTYPES.Admin) {
                const { from } = { from: { pathname: "/admin/addproperty" } };
                this.props.parm.children.props.history.push(from);
            } else {
                const { from } = { from: { pathname: "/user/addproperty" } };
                this.props.parm.children.props.history.push(from);
            }
        } else {
            document.getElementById("submitproperty").click();
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        const { token, userType, footerProperties, isContactOpen } = this.state;
        return (
            <div className="footerposition" onClick={() => this.handleClickOutside(this)}>
                <div className="home-footer">
                    <Container>
                        <Row className="footer-content">
                            {!token ?
                                <Col md={12} className="newsletters">
                                    {CONFIG.REGION_ID === REGIONS.US ?
                                        // ''
                                        // : CONFIG.REGION_ID === REGIONS.UK ?
                                        <div>
                                            <h2>Subscribe to our newsletter</h2>
                                            <p>Stay up-to-date on new listings, industry news, market insights, interviews and more!</p>
                                            <div className="reactHubspotForms">
                                                <HubspotForm
                                                    portalId='4297816'
                                                    formId='ba6678dc-56ea-4dc7-9def-bcc48cf8aca8'
                                                />
                                            </div>
                                        </div>
                                        : ''}
                                </Col>
                                : ''}
                            <Col sm={4} className="contact-footer">
                                <h4>Contact us</h4>
                                <div className="leftColor-separator-small margin-b-2"></div>
                                <div className="list">
                                    {CONFIG.REGION_ID === REGIONS.US ?
                                        <div className="list-item">
                                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                                            <div className="list-content">
                                            26400 Lahser Rd. Suite 210<br />Southfield, MI 48033
                                            </div>
                                        </div>
                                        : CONFIG.REGION_ID === REGIONS.UK ?
                                            <div></div>
                                            : ""}

                                    <div className="list-item">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <div className="list-content">
                                            <a href={"mailto:" + CONFIG.SUPPORT_EMAIL}>{CONFIG.SUPPORT_EMAIL}</a>
                                        </div>
                                    </div>

                                    <div className="list-item">
                                        <FontAwesomeIcon icon={faPhone} />
                                        <div className="list-content">
                                            {CONFIG.REGION_ID === REGIONS.US ?
                                                <a href="tel:313-484-4670">(800) 377-3098 </a>
                                                : CONFIG.REGION_ID === REGIONS.UK ?
                                                    <a href="tel:020 3048 3123"> 020 3048 3123</a>
                                                    : ""}
                                        </div>
                                    </div>
                                    <div className="socialicon">
                                        <p>
                                            <a href={CONFIG.REGION_ID === REGIONS.UK ? 'https://www.linkedin.com/company/rv-park-iq'
                                                : 'https://www.linkedin.com/company/rv-park-iq'} target="blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                                            <a href={CONFIG.REGION_ID === REGIONS.UK ? 'https://twitter.com/rvparkiq'
                                                : 'https://twitter.com/rvparkiq'} target="blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                                            <a href={CONFIG.REGION_ID === REGIONS.UK ? 'https://www.facebook.com/RV-Park-IQ-113812351310680/'
                                                : 'https://www.facebook.com/RV-Park-IQ-113812351310680/'} target="blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookSquare} /></a>
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <h4>Quick Links</h4>
                                <div className="leftColor-separator-small margin-b-2"></div>
                                <div className="list quicklinks">
                                    <ul>
                                        <li><Link to="/" onClick={() => this.scrollToTop()}>Home</Link></li>
                                        <li>
                                            <a href={CONFIG.RVPE_URL}>Market Data</a>
                                        </li>
                                        <li>
                                            <Link to="/listings" onClick={() => this.scrollToTop()}>Listings</Link>
                                        </li>
                                        <li><Link to="/vendors" onClick={() => this.scrollToTop()}>Vendors</Link></li>
                                        <li><a href="/industry-insights/" rel="noreferrer" onClick={() => this.scrollToTop()}>Industry Insights</a></li>
                                        {!token ?
                                            <li>
                                                <Link onClick={this.openContactForm.bind(this)}>Contact</Link>
                                            </li> : ''}
                                        {token ?
                                            <li>
                                                <Link to={userType === USERTYPES.Admin ? "/admin/addproperty" : "/user/addproperty"} onClick={() => this.scrollToTop()}>Submit Property</Link>
                                            </li>
                                            :
                                            <li>
                                                <Link onClick={this.submitProperty.bind(this)}>Submit Property</Link>
                                            </li>
                                        }
                                        {/* <li>
                                            <Link to="/valuation" onClick={() => this.scrollToTop()}>Request Property Valuation</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/aboutus" onClick={() => this.scrollToTop()}>About Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <h4>New Properties</h4>
                                <div className="leftColor-separator-small margin-b-2"></div>
                                {footerProperties ?
                                    footerProperties.length > 0 ? footerProperties.map((footer) =>
                                        <div className="new-properties">
                                            <Link to={"/property/" + footer.Permalink} >
                                                <div className="imgefooter">
                                                    <img src={'/PropertyImages/' + footer.PrimaryImage} onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} alt="Property Image" />
                                                </div>
                                                <div className="property-address">
                                                    <div className="p-address">{footer.Address1}, {footer.City}, {footer.StateId} {footer.ZipCode}</div>
                                                    <div className="p-date">
                                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                                        <span>{footer.ModifiedDate ? moment(footer.ModifiedDate).format(getDateFormat()) : moment(footer.CreatedDate).format(getDateFormat())}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                        : <span className="nofound">No records found</span>
                                    : ''}
                            </Col>


                        </Row >
                    </Container >
                </div >
                <div className="copyright">
                    <Container className="color-dark-blue">
                        <Row className="footer-bottom">
                            <Col md="12">
                                <p>Copyright ©{new Date().getFullYear()} Aggregate Intelligence, Inc. All Rights Reserved.</p>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <span id="contactForm" onClick={this.openContactForm.bind(this)}>
                    {isContactOpen ?
                        <ContactUs open={isContactOpen} parentMethod={this.closeContactForm} />
                        : ''}
                </span>
            </div>
        );
    }
}

export default connect()(Footer);