export const CONFIG = {
  //for SEO
  PAGE_TITLE: ' | RVParkIQ',
  DOMAIN: "https://rvparkiq.com/",
  MAIN_KEYWORDS: "listrvparks, ListRVParks, Buyers, RVParkIQ, Investors, Listing, Market, List-RV-Parks, RV List, rv list, list of RVParkIQ, list of RVParkIQ near me", 
  DEFAULT_TITLE: "RVParkIQ",
  DEFAULT_DESCRIPTION: "RVParkIQ is uniquely qualified to serve as an outsourced acquisitions and capital deployment solution for established buyers and investors within the RVParkIQ industry.",
  FAVICON_SOURCE: "https://rvparkiq.com/favicon.ico",

  // //LOCAL
  // API_URL: 'http://localhost:6001/api/',
  // ISDEV: true,
  // US: 'http://rvparkexplorer.aggregateintelligence.com',
  // UK: 'http://rvparkexplorer.aggregateintelligence.com',
  // RVPE_URL:'http://explorer.rvparkexplorer.aggregateintelligence.com/',

  // // STAGING
  // API_URL: 'http://rvparkexplorer-api.aggregateintelligence.com/api/',
  // ISDEV: false,
  // US: 'http://rvparkexplorer.aggregateintelligence.com',
  // UK: 'http://rvparkexplorer.aggregateintelligence.com',
  // RVPE_URL: 'http://explorer.rvparkexplorer.aggregateintelligence.com/',

  //LIVE
  API_URL: 'https://api.rvparkiq.com/api/',
  ISDEV: false,
  US: 'https://rvparkiq.com',
  UK: 'https://rvparkiq.com',
  RVPE_URL:'https://explorer.rvparkiq.com/',

  // //US
  REGION_ID: 1,
  SUPPORT_EMAIL: 'info@rvparkiq.com',

  // // UK
  // REGION_ID: 3,
  // SUPPORT_EMAIL: 'info@listrvparks.co.uk',

  sessionTimeout: 1000 * 60 * 60,
  PAGE_LENGTH: 10,
  GeolocationApiKey: 'AIzaSyAb8UR1jppXwZw1_B_4WLneVP6MxdS6PMU',
  TINY_MCE_KEY: '5frswc3jdh4z6cipxq9ytnvyfk9c25701f66n84n6pib9pw2',
  //  GoogleMapsApiKey: 'AIzaSyDmD4dBIX3AYtW4iej8okdDNKoto4fiqG4'
  GoogleMapsApiKey: 'AIzaSyBdI8IeUjbw-usya1YWTEsjytgGqEfj2KU',
  MapAPIKey: 'AIzaSyC7Pz17r14MeCOY3Hz5CFhnoxoCjmi9XWM',
  ReportKey: 'live9moc8dot7rotsevni6skrap5vr4w',
  //'a4yqidfqcyw81wvs1oakorjueq6dys7j81phu3lye8xc2rdx',
  US_CountryId: '2',
  DEFAULT_RADIUS: 5,
  NewsAndEventsImageWidth: 455,
  NewsAndEventsImageHeight: 270,
  PropertyImageWidth: 662,
  PropertyImageHeight: 350,
  VendorLogoWidth: 400,
  VendorLogoHeight: 110,
  VendorAdsImageWidth: 182,
  VendorAdsImageHeight: 123,
  SponsorAdsImageWidth: 384,
  SponsorAdsImageHeight: 259,
  ListingAdsImageWidth: 260,
  ListingAdsImageHeight: 353,
  NewsAdsImageWidth: 360,
  NewsAdsImageHeight: 135
}