import React, { Component } from 'react';
import '../../bundle.css'
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-image-crop/dist/ReactCrop.css';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Button, Row, Container, Col, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { showLoader, hideLoader } from '../../redux/ducks/application';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { CONFIG } from './../../Utils/config';
import {
    getFormattedInt, getFormattedFloat, REGIONS, getDateFormatString, substringStripHtmlTag, stripHtmlTags,
} from './../../Utils/utils';
import ListingTile from '../../Views/Shared/ListingTile/ListingTile';
import Geocode from "react-geocode";
import vendor_no from './../../Assets/Img/ventor-list.png';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import leadstory from './../../Assets/Img/lead-story.png';
import aboutinvestor from './../../Assets/Img/about-investor.png';
import Helmet from '../../Utils/Helmet';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            practicesData: [],
            financeNewsData: [],
            newsAndEventsData: [],
            latestNewsAndEvents: [],
            leadStories: [],
            isAdvancedSearch: false,
            isOpen: false,
            showPropertyNameList: false,
            dropdownOpen: false,
            userInput: '',
            propertyNameList: [],
            propertyTypeList: [],
            propertyTypeId: '',
            cityList: [],
            isAdvanedPopup: false,
            stateList: [],
            selectedStates: [],
            selected: [],
            setSelected: [],
            selectedPropertyType: [],
            selectedMSA: [],
            backupList: [],
            msaList: [],
            selectedValue: null,
            selectedValueMSA: null,
            stateId: '',
            MSAListId: '',
            msaId: '',
            showCityList: false,
            cityName: '',
            searchId: '',
            isRoomExpansion: false,
            isPortfolios: false,
            isExcludeProtfolios: false,
            lat: '',
            lng: '',
            searchData: [],
            cityId: '',
            measurementsList: [],
            marketFeatureList: [],
            MVPMarketPSFAverage: '',
            MVPAveragePopulationPerStore: '',
            MVPCurrentSQFTPerCapita: '',
            addressError: '',
            selectedList: null,
            noKeyWord: false,
            propertyTypeIds: '',
            displayValue: 'Property Search',
            imgId: 0,
            minPrice: '',
            priceError: false,
            maxPrice: '',
            minBuild: '',
            maxBuild: '',
            buildError: false,
            minLot: '',
            maxLot: '',
            lotError: false,
            nrsfError: false,
            defaultLotId: 1,
            proxAddress: '',
            defaultZip: '',
            defaultProximity: 5,
            defaultPSF: '',
            defaultPop: '',
            defaultSqFt: '',
            defaultShow: false,
            defaultExpansion: '',
            proxDisabled: true,
            zipDisabled: true,
            open: false,
            openSearch: false,
            openMSA: false,
            isMSAError: false,
            listingCount: null,
            amenitiesList: [],
            amenitiesId: '',
            selectedAmenities: [],
            minHouseIncome: '',
            maxHouseIncome: '',
            houseIncomeError: false,
            minServed: '',
            maxServed: '',
            servedError: false,
            proximitytoList: [],
            proximitytoId: '',
            selectedProximityto: [],
        };

        this.typeChange = this.typeChange.bind(this);
        this.handleKeyChange = this.handleKeyChange.bind(this);
        this.onKeyFocus = this.onKeyFocus.bind(this);
        this.multiselectRef = React.createRef();
        this.onStateSelect = this.onStateSelect.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.roomExpansionOnChange = this.roomExpansionOnChange.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.toggle = this.toggle.bind(this);
        this.signInPopup = this.signInPopup.bind(this);
        this.contactPopup = this.contactPopup.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        Geocode.setApiKey(CONFIG.GeolocationApiKey);
        Geocode.setLanguage("en");
        Geocode.enableDebug();
        if (window.location.pathname.indexOf('/signin') >= 0) {
            this.signInPopup();
        }
        if (window.location.pathname.indexOf('/contact') >= 0) {
            this.contactPopup();
        }

        this.autoCompleteClearEventListener();
        this.getListingCount();
        this.getPropertiesType();
        this.getMeasurementsTypes();
        this.getHomeData();
        this.getFeaturedListings();
        this.getFeaturedVendorListings();
    }

    getAmentiesList() {
        axios.get(CONFIG.API_URL + 'lookup/amenities').then(response => {
            if (response.status === 200) {
                this.setState({ amenitiesList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getProximitytoList() {
        axios.get(CONFIG.API_URL + 'lookup/amenities').then(response => {
            if (response.status === 200) {
                this.setState({ proximitytoList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    signInPopup() {
        document.getElementById("loginclick").click();
    }

    contactPopup() {
        document.getElementById("contactForm").click();
    }

    getListingCount() {
        axios.get(CONFIG.API_URL + 'home/lising/count/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {

                this.setState({ listingCount: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getFeaturedListings() {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'home/featured/listings/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                var property = response.data;

                this.setState({ featuredListings: property });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getFeaturedVendorListings() {
        let token = localStorage.getItem('accessKey');
        if (token) {
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }
        axios.get(CONFIG.API_URL + 'vendor/featured/list/' + CONFIG.REGION_ID).then(response => {
            if (response.status === 200) {
                var property = response.data;

                this.setState({ featuredVendorListings: property });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }


    handleKeyChange(e) {

        const userInput = e.currentTarget.value;

        var obj = { PropertyName: userInput };

        this.setState({ userInput: obj, noKeyWord: false });
        if (userInput.length >= 2) {
            const data = {
                "Keyword": userInput,
                "PropertyTypeIds": this.state.propertyTypeIds,
                "RegionId": CONFIG.REGION_ID

            };
            axios.post(CONFIG.API_URL + 'search', data).then(response => {
                if (response.status === 200) {

                    this.setState({ propertyNameList: response.data, showPropertyNameList: true });
                }
            })
                .catch(err => {
                    this.setState({ isLoading: false });
                });
        } else {

            if (userInput.length === 0) {
                this.setState({ propertyNameList: [], showPropertyNameList: true });
            }

        }
    }

    toggle() {
        this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
    }

    onKeyFocus() {
        const { showPropertyNameList } = this.state;
        this.setState({ showPropertyNameList: !showPropertyNameList });
    }

    onCityKeyFocus() {
        const { showPropertyNameList } = this.state;
        this.setState({ showPropertyNameList: !showPropertyNameList });
    }

    onKeyPress(e) {
        const re = /[0-9,.]+/g;
        if (e.key !== "Enter") {
            if (!re.test(e.key)) {
                e.preventDefault();
            }
        }
    }

    onLotTypeChange(e) {
        if (e.target !== null) {
            let defaultLotId = parseInt(e.target.value, 10);
            this.setState({ defaultLotId: defaultLotId })
        }
    }

    onKeyPriceNumberChange(e) {
        let minPrice = this.state.minPrice;
        let maxPrice = this.state.maxPrice;;
        if (e.target.name === "MinPrice") {
            minPrice = getFormattedInt(e.target.value.replace(/,/g, ''));
            if (maxPrice !== "" && (parseInt(minPrice.replace(/,/g, ''), 10) > parseInt(maxPrice.replace(/,/g, ''), 10))) {
                this.setState({ priceError: true })
            }
            else {
                this.setState({ priceError: false })
            }
        }
        else if (e.target.name === "MaxPrice") {
            maxPrice = getFormattedInt(e.target.value.replace(/,/g, ''));
            if (minPrice !== "" && (parseInt(minPrice.replace(/,/g, ''), 10) > parseInt(maxPrice.replace(/,/g, ''), 10))) {

                this.setState({ priceError: true })
            }
            else {
                this.setState({ priceError: false })
            }

        }

        this.setState({ minPrice: minPrice, maxPrice: maxPrice });
    }


    onKeyLotNumberChange(e) {

        let minLot = this.state.minLot;
        let maxLot = this.state.maxLot;
        if (e.target.name === "MinLotSize") {
            minLot = getFormattedInt(e.target.value.replace(/,/g, ''));
            if (maxLot !== "" && (parseInt(minLot.replace(/,/g, ''), 10) > parseInt(maxLot.replace(/,/g, ''), 10))) {
                this.setState({ lotError: true })
            }
            else {
                this.setState({ lotError: false })
            }
        }
        else if (e.target.name === "MaxLotSize") {
            maxLot = getFormattedInt(e.target.value.replace(/,/g, ''));
            if (minLot !== "" && (parseInt(minLot.replace(/,/g, ''), 10) > parseInt(maxLot.replace(/,/g, ''), 10))) {

                this.setState({ lotError: true })
            }
            else {
                this.setState({ lotError: false })
            }

        }

        this.setState({ minLot: minLot, maxLot: maxLot });
    }


    onKeyHouseIncomeChange(e) {

        let minHouseIncome = this.state.minHouseIncome;
        let maxHouseIncome = this.state.maxHouseIncome;
        if (e.target.name === "MinHouseIncomeSize") {
            minHouseIncome = getFormattedInt(e.target.value.replace(/,/g, ''));
            if (maxHouseIncome !== "" && (parseInt(minHouseIncome.replace(/,/g, ''), 10) > parseInt(maxHouseIncome.replace(/,/g, ''), 10))) {
                this.setState({ houseIncomeError: true })
            }
            else {
                this.setState({ houseIncomeError: false })
            }
        }
        else if (e.target.name === "MaxHouseIncomeSize") {
            maxHouseIncome = getFormattedInt(e.target.value.replace(/,/g, ''));
            if (minHouseIncome !== "" && (parseInt(minHouseIncome.replace(/,/g, ''), 10) > parseInt(maxHouseIncome.replace(/,/g, ''), 10))) {

                this.setState({ houseIncomeError: true })
            }
            else {
                this.setState({ houseIncomeError: false })
            }

        }

        this.setState({ minHouseIncome: minHouseIncome, maxHouseIncome: maxHouseIncome });
    }

    onKeyServedChange(e) {

        let minServed = this.state.minServed;
        let maxServed = this.state.maxServed;
        if (e.target.name === "MinServedSize") {
            minServed = getFormattedInt(e.target.value.replace(/,/g, ''));
            if (maxServed !== "" && (parseInt(minServed.replace(/,/g, ''), 10) > parseInt(maxServed.replace(/,/g, ''), 10))) {
                this.setState({ servedError: true })
            }
            else {
                this.setState({ servedError: false })
            }
        }
        else if (e.target.name === "MaxServedSize") {
            maxServed = getFormattedInt(e.target.value.replace(/,/g, ''));
            if (minServed !== "" && (parseInt(minServed.replace(/,/g, ''), 10) > parseInt(maxServed.replace(/,/g, ''), 10))) {

                this.setState({ servedError: true })
            }
            else {
                this.setState({ servedError: false })
            }

        }

        this.setState({ minServed: minServed, maxServed: maxServed });
    }

    onKeyBuildNumberChange(e) {

        let minBuild = this.state.minBuild;
        let maxBuild = this.state.maxBuild;;
        if (e.target.name === "MinSize") {
            minBuild = getFormattedInt(e.target.value.replace(/,/g, ''));
            if (maxBuild !== "" && (parseInt(minBuild.replace(/,/g, ''), 10) > parseInt(maxBuild.replace(/,/g, ''), 10))) {
                this.setState({ buildError: true })
            }
            else {
                this.setState({ buildError: false })
            }
        }
        else if (e.target.name === "MaxSize") {
            maxBuild = getFormattedInt(e.target.value.replace(/,/g, ''));
            if (minBuild !== "" && (parseInt(minBuild.replace(/,/g, ''), 10) > parseInt(maxBuild.replace(/,/g, ''), 10))) {

                this.setState({ buildError: true })
            }
            else {
                this.setState({ buildError: false })
            }
        }

        this.setState({ minBuild: minBuild, maxBuild: maxBuild });
    }


    onStateSelect = (event, selectedList, selectedItem) => {

        var stateIds = Array.prototype.map.call(selectedList, function (item) { return item.Id; }).join(",");
        var selectedStates = selectedList;
        this.setState({ selectedMSA: [], msaList: [], cityName: '', cityList: [], selectedStates, stateId: stateIds });

        if (stateIds !== "") {
            const data = {
                StateIDs: stateIds
            }

            axios.post(CONFIG.API_URL + 'lookup/msa', data).then(response => {
                if (response.status === 200) {

                    this.setState({ msaList: response.data });
                }
            })
                .catch(err => {
                    this.setState({ isLoading: false });
                });
        }
    }

    onAmenitiesSelect = (event, selectedList, selectedItem) => {
        var amenitiesId = Array.prototype.map.call(selectedList, function (item) { return item.id; }).join(",");
        this.setState({ amenitiesId: amenitiesId });
    }

    onProximitytoSelect = (event, selectedList, selectedItem) => {
        var proximitytoId = Array.prototype.map.call(selectedList, function (item) { return item.id; }).join(",");
        this.setState({ proximitytoId: proximitytoId });
    }

    onMSASelect = (event, selectedList, selectedItem) => {
        this.setState({ selectedMSA: selectedList });
    }

    typeChange(e) {
        let propertyTypeId = e.target.value;
        this.setState({ propertyTypeId: propertyTypeId })
    }

    getPropertiesType() {
        axios.get(CONFIG.API_URL + 'lookup/propertytypes').then(response => {
            if (response.status === 200) {

                this.setState({ propertyTypeList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getHomeData() {
        axios.get(CONFIG.API_URL + 'industryinsights/' + CONFIG.REGION_ID)
            .then(res => {
                this.setState({ latestNewsAndEvents: res.data.latestPosts, leadStories: res.data.featuredPosts })
            })
            .catch((err) => {

            })
    }

    toggleClose() {
        this.clear();
        this.setState({ isAdvanedPopup: false });
    }

    searchKeyword() {

        const { userInput } = this.state;
        if (userInput === '') {
            this.setState({ noKeyWord: true });
        } else if (userInput.PropertyName === "") { this.setState({ noKeyWord: true }); }
        else {
            const data = {
                SearchKeyword: userInput ? userInput.PropertyName : '',
                PropertyTypeIds: this.state.propertyTypeIds,
                RegionId: CONFIG.REGION_ID
            }

            axios.post(CONFIG.API_URL + 'search/criteria', data)
                .then(response => {

                    if (response.status === 200) {
                        let searchId = response.data;
                        this.setState({ isAdvanedPopup: false, noKeyWord: false })
                        const { from } = { from: { pathname: "/listings/" + searchId } };
                        this.props.history.push(from);
                    }
                })
                .catch(err => {
                });
        }
    }

    openAdvancedSearch() {

        Geocode.setApiKey(CONFIG.GeolocationApiKey);
        Geocode.setLanguage("en");
        Geocode.enableDebug();
        if (CONFIG.REGION_ID !== REGIONS.UK) {
            this.getLookupState();
        }
        this.getAmentiesList();
        this.getProximitytoList();
        this.setState({ isAdvanedPopup: true })
    }

    roomExpansionOnChange(e) {

        let isRoomExpansion = !this.state.isRoomExpansion;
        this.setState({ isRoomExpansion: isRoomExpansion })
    }

    onChangeAddress(e) {
        if (e.target !== null) {
            if (e.target.name === "Zip") {
                this.setState({ defaultZip: e.target.value });
            }
            else if (e.target.name === "ProximitytoAddress") {
                this.setState({ proxAddress: e.target.value });
            }
            else if (e.target.name === "Proximity") {
                var val = parseInt(e.target.value, 10);
                this.setState({ defaultProximity: val });
            }
            else if (e.target.name === "Market$PSFaverage") {
                this.setState({ defaultPSF: e.target.value });
            }
            else if (e.target.name === "AvPopStore") {
                this.setState({ defaultPop: e.target.value });
            }
            else if (e.target.name === "CurrentSqFtCaptia") {
                this.setState({ defaultSqFt: e.target.value });
            }
            else if (e.target.name === "Showonlywithroomforexpansion") {
                this.setState({ defaultShow: e.target.checked });
            }
            else if (e.target.name === "ExpansionAndPortFolio") {
                this.setState({ defaultExpansion: true });
            }
        }
    }

    onKeyFloatChange(e) {
        let name = e.target.name;
        let value = getFormattedFloat(e.target.value.replace(/,/g, ''));
        if (name === "AvPopStore") {
            this.setState({ defaultPop: value })
        }
        else if (name === "Market$PSFaverage") {
            this.setState({ defaultPSF: value })
        }

        else if (name === "CurrentSqFtCaptia") {
            this.setState({ defaultSqFt: value })
        }
    }

    handleCityChange(e) {

        var city = e.currentTarget.value;
        const obj = {
            Name: city,
            StateID: '',
        }

        this.setState({ cityName: obj });

        if (city.trim() === "") {
            this.setState({ cityList: [] });
        }
        else {
            const data = {
                Keyword: city,
                StateIDs: this.state.stateId,
                RegionID: CONFIG.REGION_ID
            }
            axios.post(CONFIG.API_URL + 'search/city', data).then(response => {

                if (response.status === 200) {

                    this.setState({ cityList: response.data, showCityList: true });
                }
            })
                .catch(err => {
                    this.setState({ isLoading: false });
                });
        }
    }

    getLookupState() {
        axios.get(CONFIG.API_URL + 'lookup/state/' + CONFIG.US_CountryId).then(response => {
            if (response.status === 200) {
                this.setState({ stateList: response.data, backupList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    getMeasurementsTypes() {
        axios.get(CONFIG.API_URL + 'lookup/measurements').then(response => {
            if (response.status === 200) {
                this.setState({ measurementsList: response.data });
            }
        })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    clear() {
        this.setState({
            selectedValue: null, selectedStates: [], stateId: '', selectedMSA: [], msaList: [], cityList: [], cityName: '', minBuild: '', maxBuild: '', minPrice: '', maxPrice: '', minLot: '', maxLot: '', defaultLotId: 1, proxAddress: '', defaultZip: '',
            defaultProximity: 5, defaultPSF: '', defaultPop: '', defaultSqFt: '', defaultShow: false, defaultExpansion: '', addressError: '', priceError: false, isMSAError: false,
            amenitiesId: '', selectedAmenities: [], minHouseIncome: '', maxHouseIncome: '', minServed: '', maxServed: '', proximitytoId: '', selectedProximityto: []
        })
    }

    advancedSearch(event, errors, values) {
        this.props.dispatch(showLoader());

        const { cityName, selectedMSA, stateId, amenitiesId } = this.state;

        var city = cityName ? cityName.Name : '';
        var cityState = cityName ? cityName.StateID : '';
        var cityId = cityName ? cityName.ID : '';
        var msaId = Array.prototype.map.call(selectedMSA, function (item) { return item.ID; }).join(",");

        this.setState({ addressError: '' });

        if (this.state.priceError === false && this.state.nrsfError === false && this.state.lotError === false && this.state.buildError === false && this.state.houseIncomeError === false) {
            if (values.ProximitytoAddress !== "") {
                if (city !== "") {
                    var address = values.ProximitytoAddress + "," + city + "," + cityState + ",USA";
                    Geocode.fromAddress(address).then(
                        response => {
                            const { lat, lng } = response.results[0].geometry.location;
                            this.setState({ lat: lat, lng: lng })
                            if (errors.length === 0) {
                                const data = {
                                    SearchKeyword: '',
                                    StateIds: stateId,
                                    MSAListIds: msaId,
                                    Address: values.ProximitytoAddress,
                                    CityIds: cityId,
                                    Zipcode: values.Zip,
                                    Proximity: values.Proximity !== "" ? parseInt(values.Proximity, 10) : "5",
                                    Latitude: this.state.lat,
                                    Longitude: this.state.lng,
                                    MinPrice: values.MinPrice !== "" ? parseInt(values.MinPrice.replace(/,/g, ''), 10) : "",
                                    MaxPrice: values.MaxPrice !== "" ? parseInt(values.MaxPrice.replace(/,/g, ''), 10) : "",
                                    MinNoofLots: values.MinLotSize !== "" ? parseInt(values.MinLotSize.replace(/,/g, ''), 10) : "",
                                    MaxNoofLots: values.MaxLotSize !== "" ? parseInt(values.MaxLotSize.replace(/,/g, ''), 10) : "",
                                    LotsizeMeasurementId: values.SizeRange !== "" ? parseInt(values.SizeRange, 10) : "",
                                    ShowOnlyWithRoomOfExpansion: values.Showonlywithroomforexpansion,
                                    PortfoliosOnly: values.ExpansionAndPortFolio === "2" ? true : false,
                                    ExcludePortfolios: values.ExpansionAndPortFolio === "3" ? true : false,
                                    RegionId: CONFIG.REGION_ID,
                                    Amentitesids: amenitiesId,
                                    Minmedianhouseholdincome: values.MinHouseIncomeSize !== "" ? parseInt(values.MinHouseIncomeSize.replace(/,/g, ''), 10) : "",
                                    Maxmedianhouseholdincome: values.MaxHouseIncomeSize !== "" ? parseInt(values.MaxHouseIncomeSize.replace(/,/g, ''), 10) : "",
                                    houseIncomesizeMeasurementId: values.SizeRange !== "" ? parseInt(values.SizeRange, 10) : "",
                                    Minpopulationserved: values.MinServedSize !== "" ? parseInt(values.MinServedSize.replace(/,/g, ''), 10) : "",
                                    Maxpopulationserved: values.MaxServedSize !== "" ? parseInt(values.MaxServedSize.replace(/,/g, ''), 10) : "",
                                    servedsizeMeasurementId: values.SizeRange !== "" ? parseInt(values.SizeRange, 10) : "",


                                }

                                axios.post(CONFIG.API_URL + 'search/criteria', data)
                                    .then(response => {

                                        if (response.status === 200) {
                                            let searchId = response.data;
                                            this.setState({ isAdvanedPopup: false })

                                            const { from } = { from: { pathname: "/listings/" + searchId } };
                                            this.props.history.push(from);

                                        }
                                    })
                                    .catch(err => {
                                        this.props.dispatch(hideLoader());
                                    });
                            }

                        },
                        error => {
                            this.props.dispatch(hideLoader());
                            this.setState({ addressError: 'Please enter valid address' });
                        }
                    );
                } else {
                    this.props.dispatch(hideLoader());
                    this.setState({ addressError: 'Please enter city' });
                }
            }
            else {
                if (errors.length === 0) {
                    const data = {
                        SearchKeyword: '',
                        StateIds: stateId,
                        MSAListIds: msaId,
                        Address: values.ProximitytoAddress,
                        CityIds: cityId,
                        Zipcode: values.Zip,
                        Proximity: values.Proximity !== "" ? parseInt(values.Proximity, 10) : "5",
                        Latitude: this.state.lat,
                        Longitude: this.state.lng,
                        MinPrice: values.MinPrice !== "" ? parseInt(values.MinPrice.replace(/,/g, ''), 10) : "",
                        MaxPrice: values.MaxPrice !== "" ? parseInt(values.MaxPrice.replace(/,/g, ''), 10) : "",
                        MinNoofLots: values.MinLotSize !== "" ? parseInt(values.MinLotSize.replace(/,/g, ''), 10) : "",
                        MaxNoofLots: values.MaxLotSize !== "" ? parseInt(values.MaxLotSize.replace(/,/g, ''), 10) : "",
                        LotsizeMeasurementId: values.SizeRange !== "" ? parseInt(values.SizeRange, 10) : "",
                        ShowOnlyWithRoomOfExpansion: values.Showonlywithroomforexpansion,
                        PortfoliosOnly: values.ExpansionAndPortFolio === "2" ? true : false,
                        ExcludePortfolios: values.ExpansionAndPortFolio === "3" ? true : false,
                        RegionId: CONFIG.REGION_ID,
                        Amentitesids: amenitiesId,
                        Minmedianhouseholdincome: values.MinHouseIncomeSize !== "" ? parseInt(values.MinHouseIncomeSize.replace(/,/g, ''), 10) : "",
                        Maxmedianhouseholdincome: values.MaxHouseIncomeSize !== "" ? parseInt(values.MaxHouseIncomeSize.replace(/,/g, ''), 10) : "",
                        houseIncomesizeMeasurementId: values.SizeRange !== "" ? parseInt(values.SizeRange, 10) : "",
                        Minpopulationserved: values.MinServedSize !== "" ? parseInt(values.MinServedSize.replace(/,/g, ''), 10) : "",
                        Maxpopulationserved: values.MaxServedSize !== "" ? parseInt(values.MaxServedSize.replace(/,/g, ''), 10) : "",
                        servedsizeMeasurementId: values.SizeRange !== "" ? parseInt(values.SizeRange, 10) : "",

                    }

                    axios.post(CONFIG.API_URL + 'search/criteria', data)
                        .then(response => {
                            if (response.status === 200) {
                                let searchId = response.data;
                                this.setState({ isAdvanedPopup: false })

                                const { from } = { from: { pathname: "/listings/" + searchId } };
                                this.props.history.push(from);
                            }
                        })
                        .catch(err => {
                            this.props.dispatch(hideLoader());
                        });
                }
            }
        }
        else
            this.props.dispatch(hideLoader());
    }

    propertyCheckBox = (checkData) => {
        let data = this.state.propertyTypeList;
        checkData.IsChecked = checkData.IsChecked === 0 ? 1 : 0;
        if (checkData.IsChecked === 1) {
            data.forEach(d => {
                if (d.Name === checkData.Name)
                    d.IsChecked = 1
            })
            this.setState({ propertyTypeList: data });
        } else if (checkData.IsChecked === 0) {
            data.forEach(d => {
                if (d.Name ===
                    checkData.Name)
                    d.IsChecked = 0
            })
            this.setState({ propertyTypeList: data });
        }

        let filterArr = [];
        let sendFinalData = this.state.propertyTypeList;
        let selectedTrueOnly = sendFinalData.filter(data => data.IsChecked === 1);
        if (selectedTrueOnly.length > 0) {
            if (selectedTrueOnly.length === 1) {
                this.setState({ displayValue: selectedTrueOnly[0].Name, imgId: 0 })

            }
            if (selectedTrueOnly.length === 2) {
                this.setState({ displayValue: selectedTrueOnly[0].Name, imgId: 1 })

            }
            else if (selectedTrueOnly.length === 3) {
                this.setState({ displayValue: selectedTrueOnly[0].Name, imgId: 2 })
            }
            else if (selectedTrueOnly.length === 4) {
                this.setState({ displayValue: selectedTrueOnly[0].Name, imgId: 3 })
            }
            else { this.setState({ displayValue: selectedTrueOnly[0].Name }) }
        }
        else {
            this.setState({ displayValue: 'Property Search', imgId: 0 })
        }
        selectedTrueOnly.forEach(d => {
            filterArr.push(d.ID)
        })

        let propertyTypeIds = filterArr.toString();
        this.setState({ propertyTypeIds })



    }

    setOpen = (open, params) => {
        this.setState({ open });
    }

    citySelect = (e, value) => {
        var cityName = value;
        this.setState({ cityName });
    }

    searchSelect = (event, property) => {
        if (property) {
            if (property.PropertyId) {
                const { from } = { from: { pathname: "/property/" + property.Permalink } };
                this.props.history.push(from);
            } else {
                this.searchKeyword();
            }
        }
    }

    setOpenSearch(openSearch) {
        this.setState({ openSearch });
    }

    setOpenMSA = (openMSA, params) => {
        const { selectedStates } = this.state;

        var isMSAError = false;
        if (selectedStates.length === 0) {
            isMSAError = true;
        }
        this.setState({ openMSA, isMSAError });
    }

    autoCompleteClearEventListener() {
        // Take the Reference of Close Button
        const close = document.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
        )[0];

        // Add a Click Event Listener to the button
        close.addEventListener("click", () => {
            // alert("Add your Own Functionality Here...");
            this.setState({ userInput: '', propertyNameList: [] });
        });
    }

    render() {
        const { listingCount, latestNewsAndEvents, leadStories, selectedStates, selectedMSA, userInput, propertyNameList, isAdvanedPopup, stateList, msaList, cityName, cityList, addressError, featuredListings, noKeyWord,
            minPrice, priceError, maxPrice, minLot, maxLot, lotError, proxAddress, defaultZip, defaultProximity, featuredVendorListings, open, openSearch, openMSA, isMSAError,
            amenitiesList, houseIncomeError, minHouseIncome, maxHouseIncome, minServed, maxServed } = this.state;
        return (
            <div className="homepage">
                <Helmet
                    title='RV Parks & Campgrounds for Sale and Market Analysis for Investors'
                    addPostfixTitle={true}
                    keywords="Home, Search, Featured Listings, Industry Insights, Featured Vendors, RV Parks & Campgrounds for Sale and Market Analysis for Investors, RV Parks, Campgrounds, Sale, Market Analysis, Investors"
                    description='Find RV Park & Campground sites for sale. Looking to build RV or campground lots?  RVParkIQ is your first stop for finding and evaluating your next investment opportunity!'
                />
                <div className={CONFIG.REGION_ID === REGIONS.UK ? "banner-homepage uk-banner-homepage" : "banner-homepage"}>
                    <Container>
                        <div className='banner-cont'>
                            <h1>Find RV Parks for Sale, Evaluate Markets Instantly</h1>
                            <p>The only market analysis platform available to RV park and campground investors and developers. Looking to build or buy RV or campground lots?  <b>RVPark<span>IQ
                                    </span></b> is your complete solution for finding and evaluating your next investment opportunity!</p>
                        </div>
                        <AvForm onSubmit={this.searchKeyword.bind(this)}>
                            <div className="box-search-home">
                                <React.Fragment>
                                    <div className={noKeyWord === true ? "inputbox-search-empty" : "inputbox-search"}>
                                        <Autocomplete
                                            id="asynchronous-demo1"
                                            name="city"
                                            open={openSearch}
                                            onOpen={() => {
                                                this.setOpenSearch(true);
                                            }}
                                            onClose={() => {
                                                this.setOpenSearch(false);
                                            }}
                                            onChange={this.searchSelect}
                                            value={userInput ? userInput : ''}
                                            getOptionLabel={(option) => option ? option.PropertyName : ''}
                                            options={propertyNameList}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={"Search our " + listingCount + " properties"}
                                                    onChange={this.handleKeyChange}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </React.Fragment>
                                <Button className="button-search-home"><FontAwesomeIcon icon={faSearch} /> Search</Button>
                                <Link role="button" className="advanced-search-home advanced-search-text" onClick={this.openAdvancedSearch.bind(this)}>Advanced Search</Link>
                            </div>
                        </AvForm>
                    </Container>
                    <div className="clear"></div>
                </div>
                <div className="clear"></div>
                <div className="home-content">
                    <Container className='about-invest'>
                        <Row className="about-rv-in">
                            <Col sm={6}>
                                <h3>About <span>RVPark</span>IQ</h3>
                                <h2>Make Data-Driven Decisions on RV Park and Campground Investment Opportunities</h2>
                              <p>With RVPark<span>IQ</span> you can find RV park and campground properties for sale. RVPark<span>IQ</span> also offers instant market insights so you can evaluate and perform preliminary due diligence - whether you plan on buying or building.</p>
                             <h4>Instant Market Analysis</h4>
                              <p><a href="https://explorer.rvparkiq.com">Market Explorer</a> is an easy-to-use, interactive mapping and data analysis tool for investors and developers. Identify potential markets with access to key data such as:</p>
                                <div className='property-de-main'>
                                    <div className='property-de-cont'><ul><li className='propertydets'>Park Size & Amenities</li>
                                        <li className='average-pa'>Property & Ownership Details</li>
                                    </ul></div>
                                    <div className='property-de-cont'><ul><li className='valuation-icn'> Valuation & Debt Information</li>
                                        <li className='supply-icn'>  Market Demand & Supply Analysis</li>
                                    </ul></div>
                                    <div className='property-de-cont'>
                                        <ul><li className='seasonality-icn'>Seasonality</li>
                                        <li className='amenitie-icn'> …and more!</li>
                                        </ul>
                                    </div></div>
                            </Col>
                            <Col sm={6}>
                                <div className='about-in-img'>
                                    <img src={aboutinvestor} alt="About Investor" className='width-100 height-100'/></div>
                                <div className='try-btn-cont'>
                                    <div className='try-it-btn'><a href={CONFIG.RVPE_URL + "account-subscription?type=free"} target="_blank" rel="noreferrer">Try it for FREE </a></div>
                                    <div className='pricing-in-btn'><a href={CONFIG.RVPE_URL + "market-data#pricing-details"} target="_blank" rel="noreferrer">Pricing </a></div>
                                </div>
                            </Col>
                            <Col sm={12} className="demand-cont">
                            <h4>Understand Market Demand</h4>
                            <p>The <a href="https://explorer.rvparkiq.com">Market Explorer</a> platform offers a unique and proprietary score to help investors determine the demand for new RV parks and campgrounds in any market. The Market Demand Score uses demographic and supply data to determine the market’s willingness to camp and therefore measure the demand for new sites. Try Market Explorer now for free!</p>
                                </Col>
                        </Row>
                    </Container>
                    <Container className='featured-cont'>
                        {featuredListings ?
                            <React.Fragment>
                                <Col sm={12} className="page-header padding-t40">
                                    <h2 className="page-title margin-b-15">Today's featured listings</h2>
                                    <Row className="padding-t-0">
                                        <Col sm={4}></Col>
                                        <Col sm={4}>
                                            <div className="title-separator1"></div>
                                        </Col>
                                        <Col sm={4}></Col>
                                    </Row>
                                    <div>
                                        <a className="blue-large-button" role="button" href="/listings">View All Listings</a>
                                    </div>
                                </Col>
                                <Row className="margin-b-20">
                                    {featuredListings.length > 0 ? featuredListings.map(property =>
                                        <Col sm={4} className="margin-t25 margin-b25 col-custom-padding">
                                            <ListingTile key={property.PropertyId} property={property} isHome={true} />
                                        </Col>
                                    ) : <Col md={12} className="text-align-center no-records-found">No Featured Listings Found</Col>}
                                </Row>
                            </React.Fragment>
                            : ''}
                        <Row className="eventscontent">

                            <Col sm={12} className="margin-b25">
                                <h2 className="page-title margin-b-10 title-hover"><a href="/industry-insights/" rel="noreferrer">INDUSTRY INSIGHTS</a>
                                </h2>
                                <Row className="padding-t-0">
                                    <Col sm={4}></Col>
                                    <Col sm={4}>
                                        <div className="title-separator1"></div>
                                    </Col>
                                    <Col sm={4}></Col>
                                </Row>
                            </Col>
                            <Col sm={8} >
                                <div className="lead-story">
                                    <div className="overlay-story">
                                        {leadStories.length > 0 ?
                                            <Carousel showArrows={true} showThumbs={false}
                                                autoPlay={true} infiniteLoop={true} interval={3000} transitionTime={60}>
                                                {leadStories.map((data) =>
                                                    <div className="slider-img">
                                                        <img src={'/industry-insights/wp-content/uploads/' + data.image} onError={(e) => { e.target.onerror = null; e.target.src = leadstory }} className="width-100 height-100" alt="Industry Insights" />

                                                        <a href={data.guid} rel="noreferrer">
                                                            <div className="legend">
                                                                {data.categories ?
                                                                    <div className="display-fullflex">
                                                                        {data.categories.includes('Best') ? <div className="category-name best-practices">Best Practices</div> : ''}
                                                                        {data.categories.includes('Finance') ? <div className="category-name fin-news">Finance News</div> : ''}
                                                                        {data.categories.includes('Transactions') ? <div className="category-name recent-news">Recent Transactions</div> : ''}
                                                                        {data.categories.includes('Sponsored') ? <div className="category-name spon-news">Sponsored</div> : ''}
                                                                        {data.categories.includes('Talks') ? <div className="category-name tech-news">Tech Talks</div> : ''}
                                                                        {data.categories.includes('Experts') ? <div className="category-name tech-news">Eavesdropping with the Experts</div> : ''}
                                                                        {data.categories.includes('Development') ? <div className="category-name tech-news">Development News</div> : ''}
                                                                    </div>
                                                                    : ''}
                                                                <h4>{data.post_title.length > 80 ? data.post_title.substring(0, 80) + '...' : data.post_title}</h4>
                                                                <p className="para-height51" >{stripHtmlTags(data.post_content, false, 260)}</p>
                                                                <p className="margin-with-content">
                                                                    <span className="lead-caleder"><FontAwesomeIcon icon={faCalendarAlt} />{moment(data.post_date).format(getDateFormatString())}</span>
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )}
                                            </Carousel>
                                            :
                                            <div className="slider-img">
                                                <img src={leadstory} className="width-100 height-100" alt="leadstory" />
                                            </div>}
                                    </div>
                                </div>
                                <div className="slider-below">
                                    <Row>
                                        {
                                            latestNewsAndEvents && latestNewsAndEvents.length > 3 ? latestNewsAndEvents.slice(3, 5).map((prac) =>
                                                <React.Fragment>
                                                    <Col md={6}>
                                                        <a href={prac.guid} rel="noreferrer">
                                                            <div className="news-box">
                                                                {prac.categories ?
                                                                    <div className="display-fullflex">
                                                                        {prac.categories.includes('Best') ? <div className="category-name best-practices">Best Practices</div> : ''}
                                                                        {prac.categories.includes('Finance') ? <div className="category-name fin-news">Finance News</div> : ''}
                                                                        {prac.categories.includes('Transactions') ? <div className="category-name recent-news">Recent Transactions</div> : ''}
                                                                        {prac.categories.includes('Sponsored') ? <div className="category-name spon-news">Sponsored</div> : ''}
                                                                        {prac.categories.includes('Talks') ? <div className="category-name tech-news">Tech Talks</div> : ''}
                                                                        {prac.categories.includes('Experts') ? <div className="category-name tech-news">Eavesdropping with the Experts</div> : ''}
                                                                        {prac.categories.includes('Development') ? <div className="category-name tech-news">Development News</div> : ''}
                                                                    </div>
                                                                    : ''}
                                                                <h4 title={prac.post_title}>{prac.post_title}</h4>
                                                                <div className="news-para-height"><p>{substringStripHtmlTag(prac.post_content, 158)}</p></div>
                                                                <Row className="month-date">
                                                                    <Col sm={6}><span className="month"><FontAwesomeIcon icon={faCalendarAlt} />{moment(prac.post_date).format(getDateFormatString())}</span></Col>
                                                                </Row>
                                                            </div>
                                                        </a>
                                                    </Col>
                                                </React.Fragment>
                                            ) : ''
                                        }
                                    </Row>
                                </div>
                            </Col>
                            <Col sm={4} className="">
                                {
                                    latestNewsAndEvents && latestNewsAndEvents.length > 0 ? latestNewsAndEvents.slice(0, 3).map((prac) =>
                                        <React.Fragment>
                                            <a href={prac.guid}>
                                                <div className="news-box">
                                                    {prac.categories ?
                                                        <div className="display-fullflex">
                                                            {prac.categories.includes('Best') ? <div className="category-name best-practices">Best Practices</div> : ''}
                                                            {prac.categories.includes('Finance') ? <div className="category-name fin-news">Finance News</div> : ''}
                                                            {prac.categories.includes('Transactions') ? <div className="category-name recent-news">Recent Transactions</div> : ''}
                                                            {prac.categories.includes('Sponsored') ? <div className="category-name spon-news">Sponsored</div> : ''}
                                                            {prac.categories.includes('Talks') ? <div className="category-name tech-news">Tech Talks</div> : ''}
                                                            {prac.categories.includes('Experts') ? <div className="category-name tech-news">Eavesdropping with the Experts</div> : ''}
                                                            {prac.categories.includes('Development') ? <div className="category-name tech-news">Development News</div> : ''}
                                                        </div>
                                                        : ''}
                                                    <h4 title={prac.post_title}>{prac.post_title}</h4>
                                                    <div className="news-para-height"><p>{substringStripHtmlTag(prac.post_content, 158)}</p></div>
                                                    <Row className="month-date">
                                                        <Col sm={6}><span className="month"><FontAwesomeIcon icon={faCalendarAlt} />{moment(prac.post_date).format(getDateFormatString())}</span></Col>
                                                    </Row>
                                                </div>
                                            </a>
                                        </React.Fragment>
                                    ) : ''
                                }
                            </Col>
                            {featuredVendorListings ?
                                <React.Fragment>
                                    <Col sm={12} className="margin-t25 margin-b25">
                                        <h2 className="page-title margin-b-10 title-hover"><Link to="/vendors">Featured Vendors</Link></h2>
                                        <Row className="padding-t-0">
                                            <Col sm={4}></Col>
                                            <Col sm={4}>
                                                <div className="title-separator1"></div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Row className="width-100 marginall-auto">
                                        {featuredVendorListings.length > 0 ? featuredVendorListings.map(vendor =>
                                            <Col sm={4}>

                                                <div className="home-ad">
                                                    <div className="ad-home-size">
                                                        <img src={'/VendorLogos/' + vendor.Logo} onError={(e) => { e.target.onerror = null; e.target.src = vendor_no }} className="width-100  height-100" alt="Featured Vendors" />
                                                    </div>
                                                    <div className="home-content-box">
                                                        <h6>{vendor.CompanyName}</h6>
                                                        <p className="para-margin-b" >{substringStripHtmlTag(vendor.Description, 250)}  <Link to={"/vendors/profile/" + vendor.Permalink}>Read More</Link></p>
                                                    </div>
                                                </div>
                                            </Col>
                                        ) : <Col md={12} className="text-align-center no-records-found">No Featured Vendors Found</Col>}
                                    </Row>
                                </React.Fragment>
                                : ''}
                        </Row>
                    </Container>
                </div><div>
                    <Modal size="md" isOpen={isAdvanedPopup} toggle={this.toggleClose.bind(this)} className="pop-up-model-home">
                        <ModalHeader toggle={this.toggleClose.bind(this)}>
                            Advanced Search
                        </ModalHeader>
                        <ModalBody>
                            <AvForm onSubmit={this.advancedSearch.bind(this)} ref={c => (this.myFormRef = c)}>
                                <Row>
                                    <Col sm={4}>
                                        <div >
                                            <h4 className="heading-margin-extra">Location</h4>
                                            {CONFIG.REGION_ID !== REGIONS.UK ?
                                                <React.Fragment>
                                                    <div className="disply-flex width-100 city-selection statescroll">
                                                        <Label>State:</Label>
                                                        <div className="width-100">
                                                            <Autocomplete
                                                                multiple
                                                                id="tags-standard"
                                                                options={stateList}
                                                                value={selectedStates}
                                                                getOptionLabel={(option) => option ? option.Id : ''}
                                                                onChange={this.onStateSelect}
                                                                renderInput={(params) => (
                                                                    <div className="text-state">
                                                                        <TextField
                                                                            {...params}
                                                                            variant="outlined"
                                                                        />
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="disply-flex width-100 city-selection statescroll" onKeyPress={selectedStates && selectedStates.length > 0 ? '' : () => this.onKeyChangemultiMSA()}>
                                                        <Label>MSA:</Label>
                                                        <div className="width-100">
                                                            <Autocomplete
                                                                multiple
                                                                id="tags-standard"
                                                                open={openMSA}
                                                                onOpen={() => {
                                                                    this.setOpenMSA(true);
                                                                }}
                                                                onClose={() => {
                                                                    this.setOpenMSA(false);
                                                                }}
                                                                options={msaList}
                                                                value={selectedMSA}
                                                                getOptionLabel={(option) => option ? option.Name : ''}
                                                                onChange={this.onMSASelect}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        error={isMSAError}
                                                                        helperText={isMSAError ? "Please select state" : ''}
                                                                        {...params}
                                                                        variant="outlined"
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                                : ''}
                                            {CONFIG.REGION_ID === REGIONS.US ?
                                                <div className="disply-flex width-100 city-selection last-field">
                                                    <Label className="city-lable">City:&nbsp;&nbsp;</Label>
                                                    <div className="width-100">
                                                        <Autocomplete
                                                            id="asynchronous-demo"
                                                            name="city"
                                                            open={open}
                                                            onOpen={() => {
                                                                this.setOpen(true);
                                                            }}
                                                            onClose={() => {
                                                                this.setOpen(false);
                                                            }}
                                                            onChange={this.citySelect}
                                                            value={cityName ? cityName : ''}
                                                            getOptionLabel={(option) => option ? option.StateID ? option.Name + ", " + option.StateID : option.Name : ''}
                                                            options={cityList}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    variant="outlined"
                                                                    onChange={this.handleCityChange}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <React.Fragment>
                                                                                {params.InputProps.endAdornment}
                                                                            </React.Fragment>
                                                                        ),
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                : CONFIG.REGION_ID === REGIONS.UK ?
                                                    <div className="city-selection2 town-city">
                                                        <Label >Town/City:</Label>
                                                        <div className="width-100">
                                                            <Autocomplete
                                                                id="asynchronous-demo"
                                                                name="city"
                                                                open={open}
                                                                onOpen={() => {
                                                                    this.setOpen(true);
                                                                }}
                                                                onClose={() => {
                                                                    this.setOpen(false);
                                                                }}
                                                                onChange={this.citySelect}
                                                                value={cityName ? cityName : ''}
                                                                getOptionLabel={(option) => option ? option.StateID ? option.Name + ", " + option.StateID : option.Name : ''}
                                                                options={cityList}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                        onChange={this.handleCityChange}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            endAdornment: (
                                                                                <React.Fragment>
                                                                                    {params.InputProps.endAdornment}
                                                                                </React.Fragment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                    : ""}

                                            {addressError ? <span className="error-ad-search1 error-ad-search5">{addressError}</span> : ''}
                                            <AvField name="ProximitytoAddress" maxLength="255" type="text" value={proxAddress} label="Street Address" onChange={this.onChangeAddress} autoComplete="chrome-off" />
                                            <AvField name="Zip" maxLength="255" type="text" value={defaultZip} label={CONFIG.REGION_ID === REGIONS.UK ? "Postcode" : "Zip Code"} onChange={this.onChangeAddress} autoComplete="chrome-off" />
                                            <div className="within-field">
                                                <AvField type="select" name="Proximity" label="Within" value={defaultProximity} onChange={this.onChangeAddress}>
                                                    <option value={5}>5</option>
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </AvField>
                                                <span>miles of this address</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <div>
                                            <h4>Price and Features</h4>
                                            <h6 className="heading-margin-reduce">Price</h6>
                                            <Row>
                                                {priceError === true ? <span className="error-ad-search1">The maximum value can't be less than the minimum value</span> : ''}
                                                <div className="padding-search width-47">
                                                    <div className="max-min-price">
                                                        <AvField name="MinPrice" maxLength="13" value={minPrice} placeholder="Min" onChange={(e) => this.onKeyPriceNumberChange(e)} onKeyPress={(e) => this.onKeyPress(e)} />
                                                    </div>
                                                </div>
                                                <div className="width-6">
                                                    <span>To</span>
                                                </div>
                                                <div className="padding-search2 width-47">
                                                    <div className="max-min-price">
                                                        <AvField name="MaxPrice" maxLength="13" value={maxPrice} placeholder="Max" onChange={(e) => this.onKeyPriceNumberChange(e)} onKeyPress={(e) => this.onKeyPress(e)} />
                                                    </div>
                                                </div>
                                            </Row>
                                            <h6>Number of Lots</h6>
                                            <Row>
                                                {lotError === true ? <span className="error-ad-search1 error-ad-search3">The maximum value can't be less than the minimum value.</span> : ''}
                                                <div className="padding-search width-47">
                                                    <div className="max-min-price2">
                                                        <AvField name="MinLotSize" maxLength="13" value={minLot} placeholder="Min" onChange={(e) => this.onKeyLotNumberChange(e)} onKeyPress={(e) => this.onKeyPress(e)} />
                                                    </div>
                                                </div>
                                                <div className="width-6">
                                                    <span>To</span>
                                                </div>
                                                <div className="padding-search2 width-47">
                                                    <div className="max-min-price2">
                                                        <AvField name="MaxLotSize" maxLength="13" value={maxLot} placeholder="Max" onChange={(e) => this.onKeyLotNumberChange(e)} onKeyPress={(e) => this.onKeyPress(e)} />
                                                    </div>
                                                </div>
                                            </Row>

                                            <h6>Amenities</h6>
                                            <Row>
                                                <div className="disply-flex width-100 city-selection statescroll">
                                                    <div className="width-100">
                                                        <Autocomplete
                                                            multiple
                                                            name="amenities"
                                                            id="tags-standard"
                                                            options={amenitiesList}
                                                            // value={selectedAmenities}
                                                            getOptionLabel={(option) => option ? option.name : ''}
                                                            onChange={this.onAmenitiesSelect}
                                                            renderInput={(params) => (
                                                                <div className="text-amenities">
                                                                    <TextField
                                                                        {...params}
                                                                        variant="outlined"
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col sm={4} className="no-right-margin ">
                                        <div>
                                            <h4 className="heading-margin-extra">Market Data</h4>
                                            <div className="market-radius">
                                                <h6>Median Household Income </h6>
                                                <Row>
                                                    {houseIncomeError === true ? <span className="error-ad-search1 error-ad-search3">The maximum value can't be less than the minimum value.</span> : ''}
                                                    <div className="padding-search width-47">
                                                        <div className="max-min-price2">
                                                            <AvField name="MinHouseIncomeSize" maxLength="13" value={minHouseIncome} placeholder="Min" onChange={(e) => this.onKeyHouseIncomeChange(e)} onKeyPress={(e) => this.onKeyPress(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="width-6">
                                                        <span>To</span>
                                                    </div>
                                                    <div className="padding-search2 width-47">
                                                        <div className="max-min-price2">
                                                            <AvField name="MaxHouseIncomeSize" maxLength="13" value={maxHouseIncome} placeholder="Max" onChange={(e) => this.onKeyHouseIncomeChange(e)} onKeyPress={(e) => this.onKeyPress(e)} />
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                            <div className="market-radius">
                                                <h6>Population Served </h6>
                                                <Row>
                                                    <div className="padding-search width-47">
                                                        <div className="max-min-price2">
                                                            <AvField name="MinServedSize" maxLength="13" value={minServed} placeholder="Min" onChange={(e) => this.onKeyServedChange(e)} onKeyPress={(e) => this.onKeyPress(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="width-6">
                                                        <span>To</span>
                                                    </div>
                                                    <div className="padding-search2 width-47">
                                                        <div className="max-min-price2">
                                                            <AvField name="MaxServedSize" maxLength="13" value={maxServed} placeholder="Max" onChange={(e) => this.onKeyServedChange(e)} onKeyPress={(e) => this.onKeyPress(e)} />
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Col sm={12} className="text-align-right margin-t-15">
                                    <Button id="btn" className="save-btn"><FontAwesomeIcon icon={faSearch} /> Search</Button>
                                    <Button className="reset-btn padding-clear-home no-left-margin" onClick={this.clear.bind(this)}>Clear</Button>
                                </Col>
                            </AvForm>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default connect()(Home);
