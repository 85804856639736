import React from "react";
import Head from "react-helmet";
import { CONFIG } from "./config";

export default function Helmet({
  title = CONFIG.DEFAULT_TITLE,
  description = CONFIG.DEFAULT_DESCRIPTION,
  link,
  keywords = "",
  addPostfixTitle = false,
  noIndex = false,
  children = null,
}) {

  let metaTitle
  if (addPostfixTitle) {
    metaTitle = title + CONFIG.PAGE_TITLE;
  } else {
    metaTitle = CONFIG.DEFAULT_TITLE;
  }
  const metaDesc = description ? description : CONFIG.DEFAULT_DESCRIPTION;
  const metaLink = link ? CONFIG.DOMAIN + link : CONFIG.DOMAIN;  
  const metaKeywords = keywords.length > 0 ? CONFIG.MAIN_KEYWORDS + ", " + keywords : CONFIG.MAIN_KEYWORDS;
  const metaRobots = noIndex ? "noindex, nofollow" : "index, follow";

  return (
    <Head>
      <html lang="en" />
      <link rel="alternate" href="https://rvparkiq.com/" hrefLang="en" />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDesc} />
      <link rel="canonical" href={metaLink} />   
      <meta name="keywords" content={metaKeywords} />
      <meta name="robots" content={metaRobots} />
      <link rel="icon" content={CONFIG.FAVICON_SOURCE} />
      <meta name="referrer" content="origin-when-crossorigin" />
      {children}
    </Head>
  )
}